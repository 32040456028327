import React from "react";
import { Link } from "react-router-dom";
import loginImg from "../../assets/images/login.png";
import logoImg from "../../assets/images/logo.svg";

export const NotFound404 = () => {
  return (
    <>
      <div className="login-wrap">
        <div className="logo-div">
          <img src={loginImg} alt="LOGIN" />
        </div>
        <div className="form-div">
          <a href="#">
            <img src={logoImg} alt="logo" className="login-logo" />
          </a>
          <div className="form-wrap enter-otp-wrap">
            <h2>Opp! Page not Found</h2>
            
              <Link className="btn blue-btn mt-3" to="/login">
                Go to home
              </Link>
          </div>
        </div>
      </div>
    </>
  );
};
